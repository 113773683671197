import { billingDaysChoices, getInvalidBillingDays } from "@smartrr/shared/utils/dateUtils";
import { useEffect, useMemo, useState } from "react";

import { loadSetup } from "@vendor-app/app/_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useDisabledDatesByBillingDays = (day: Date, omitDates?: Date[] | undefined) => {
  const dispatch = useSmartrrVendorDispatch();
  const isLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoadingSetup);
  const dayVals = useMemo(() => billingDaysChoices.map(billingDay => billingDay.value), []);
  const setupBillingDays = useSmartrrVendorSelector(state => state.vendorOrganizations.setup.billingDays);
  const [selectedBillingDays, setSelectedBillingDays] = useState<string[]>(setupBillingDays ?? dayVals);

  useEffect(() => {
    if (!isLoading && setupBillingDays) {
      setSelectedBillingDays(setupBillingDays);
    }
  }, [isLoading, setupBillingDays]);

  useEffect(() => {
    // re-requesting setup data to ensure we have the most up-to-date billing days
    dispatch(loadSetup());
  }, []);

  return useMemo(() => getInvalidBillingDays(selectedBillingDays, day, omitDates), [selectedBillingDays, day]);
};
