import styled from "styled-components";

export const SortingTypeContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;

    .Polaris-Button__Content {
      color: #2c6ecb;

      .Polaris-Icon__Svg {
        fill: #2c6ecb;
      }
    }
  }
`;

export const SortContainer = styled.div`
  overflow: hidden;

  .Polaris-LegacyCard__Section {
    padding: 8px !important;
  }

  & .Polaris-Button {
    justify-content: flex-start;
    margin: 0 !important;
    width: 100%;

    .Polaris-Button__Content {
      justify-content: flex-start;
    }
  }
`;

export const SortingValueContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;
    color: #202223;
    padding: var(--p-space-2);
  }
`;

export const SelectBoldText = styled.div`
  .Polaris-Select__SelectedOption {
    font-weight: 500;
  }
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 62vh;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: space-between;
  padding: 15px;
`;

export const PaginationContainer = styled.div`
  & > div {
    & > nav {
      > div {
        display: flex;
        justify-content: space-between;

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }

        & .Polaris-ButtonGroup__Item:last-child {
          .Polaris-Button {
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }
`;

export const FailedBadgeContainer = styled.div`
  .Polaris-Badge {
    background-color: #000;
    color: #fff;
  }
`;

export const NoUpcomingDateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: flex-start;

  .Polaris-Icon {
    height: 15px;
    margin: 0;
  }
`;

export const BannerContainer = styled.div`
  & > .Polaris-Banner {
    border-radius: 8px;
  }
`;

export const NoSellingPlanTooltip = styled.div`
  border-radius: var(--pc-tooltip-border-radius);
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 200px;
  padding: 5px;
`;

export const NoSellingPlanDisclaimer = styled.div`
  align-items: center;
  background-color: var(--p-color-bg-disabled);
  border: var(--p-border-width-1) solid var(--p-color-border-disabled);
  border-radius: var(--p-border-radius-1);
  box-shadow: var(--p-color-bg-disabled);
  display: flex;
  justify-content: space-between;
  min-height: 2.25rem;
  padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-2)
    calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-3);

  & > .Polaris-Icon {
    margin: 0;
  }
`;

export const ProductNameContainer = styled.span`
  display: block;
  max-width: 15vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FixedSearch = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const SellingPlanSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 250px;
  min-width: 150px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GridWithCustomWidth = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 25% 50% 20% 20px;
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  .Polaris-IndexTable__TableCell {
    vertical-align: middle;
  }
`;

export const NumberFieldNoArrows = styled.div`
  .Polaris-TextField__Spinner {
    display: none;
  }
`;

export const QuantityBadge = styled.div`
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: #e4e5e7;
  border-radius: 10px;
  color: #202223;
  display: flex;
  font-size: 13px;
  justify-content: center;
  line-height: 16px;
  position: absolute;
  right: -8px;

  top: -8px;
  width: 20px;
  z-index: 10;
`;
