import { flatten, intersectionBy, uniq } from "lodash";

import { CurrencyCode } from "@smartrr/shared/currencyCode";

import { SELLING_PLAN_GROUP_ADDON_TAG, SELLING_PLAN_GROUP_TRENDING_LISTS_TAG } from "../../constants";
import { IPurchasable } from "../../entities/Purchasable";
import { IPurchasableVariant } from "../../entities/PurchasableVariant";
import {
  ISmartrrAddOnsConfig,
  ISmartrrAddOnsConfigSellingPlan,
  ISmartrrSellingPlan,
  ISmartrrSellingPlanGroup,
  SmartrrSellingPlanFixedPricingPolicy,
  SmartrrSellingPlanPricingPolicy,
  SmartrrSellingPlanPricingPolicyAdjustmentTypeFixedAmount,
  SmartrrSellingPlanPricingPolicyAdjustmentTypePercentage,
  SmartrrSellingPlanPricingPolicyAdjustmentTypePrice,
} from "../../entities/SellingPlanGroup";
import { SellingPlanPricingPolicyAdjustmentType } from "../../shopifyGraphQL/api";
import {
  IShopifyLiquidSellingPlan,
  IShopifyLiquidSellingPlanGroup,
  IShopifyLiquidSellingPlanWithGroupIdAndName,
} from "../../shopifyLiquid/selling_plan_group";
import { shopifyGidToNumber } from "../ensureShopifyGid";
import { unformatMoney } from "../formatMoney";

// returns plans that are common to all provided purchasables
export function getSellingPlansForPurchasables(
  purchasables: IPurchasable[],
  sellingPlanGroupsByProductId: { [id: string]: IShopifyLiquidSellingPlanGroup[] }
) {
  const sellingPlans: IShopifyLiquidSellingPlanWithGroupIdAndName[][] = [];
  for (const purchasable of purchasables) {
    if (!purchasable.shopifyId) {
      continue;
    }
    const sellingPlanGroupsForProduct =
      sellingPlanGroupsByProductId[shopifyGidToNumber(purchasable.shopifyId)] || [];
    const sellingPlansWithGroupIdAndName: IShopifyLiquidSellingPlanWithGroupIdAndName[] = flatten(
      sellingPlanGroupsForProduct.map(g =>
        g.selling_plans.map(sp => ({
          ...sp,
          group_id: g.id,
          group_name: g.name,
        }))
      )
    );

    sellingPlans.push([...sellingPlansWithGroupIdAndName]);
  }
  return intersectionBy(...sellingPlans, plan => plan.id);
}

export function getSellingPlansForVariants(
  variants: IPurchasableVariant[],
  sellingPlanGroupsByVariantId: { [id: string]: IShopifyLiquidSellingPlanGroup[] },
  sellingPlanGroupsByProductId: { [id: string]: IShopifyLiquidSellingPlanGroup[] },
  variantToPurchasableMap: { [id: string]: IPurchasable }
) {
  const sellingPlans: IShopifyLiquidSellingPlanWithGroupIdAndName[][] = [];
  for (const variant of variants) {
    if (!variant.shopifyId) {
      continue;
    }
    const purchasableForVariant = variantToPurchasableMap[variant.id];
    const sellingPlanGroupsForProduct = purchasableForVariant?.shopifyId
      ? sellingPlanGroupsByProductId[shopifyGidToNumber(purchasableForVariant.shopifyId)] || []
      : [];
    const sellingPlanGroupsForVariant = sellingPlanGroupsByVariantId[shopifyGidToNumber(variant.shopifyId)] || [];
    const variantSellingPlansWithGroupIdAndName: IShopifyLiquidSellingPlanWithGroupIdAndName[] = flatten(
      sellingPlanGroupsForVariant
        .filter(
          spg =>
            !spg.name.includes(SELLING_PLAN_GROUP_ADDON_TAG) &&
            !spg.name.includes(SELLING_PLAN_GROUP_TRENDING_LISTS_TAG)
        )
        .map(g => g.selling_plans.map(sp => ({ ...sp, group_id: g.id, group_name: g.name })))
    );
    const productSellingPlansWithGroupIdAndName: IShopifyLiquidSellingPlanWithGroupIdAndName[] = flatten(
      sellingPlanGroupsForProduct
        .filter(
          spg =>
            !spg.name.includes(SELLING_PLAN_GROUP_ADDON_TAG) &&
            !spg.name.includes(SELLING_PLAN_GROUP_TRENDING_LISTS_TAG)
        )
        .map(g => g.selling_plans.map(sp => ({ ...sp, group_id: g.id, group_name: g.name })))
    );
    sellingPlans.push([...variantSellingPlansWithGroupIdAndName, ...productSellingPlansWithGroupIdAndName]);
  }
  return intersectionBy(...sellingPlans, plan => plan.id);
}

export function getPlanAndEligiblePurchasablesForSellingPlanId(
  purchasables: IPurchasable[],
  sellingPlanId: string | undefined,
  sellingPlanGroupsByProductId: { [id: string]: IShopifyLiquidSellingPlanGroup[] }
):
  | {
      sellingPlan: IShopifyLiquidSellingPlan;
      purchasables: IPurchasable[];
    }
  | undefined {
  if (!sellingPlanId) {
    return;
  }
  return findEligibleProductsForSellingPlanId(purchasables, sellingPlanGroupsByProductId, sellingPlanId);
}

export function getPlanAndEligiblePurchasablesForSmartrrSellingPlanId(
  purchasables: IPurchasable[],
  sellingPlanId: string | undefined,
  sellingPlanGroupsByProductId: { [id: string]: ISmartrrSellingPlanGroup[] }
):
  | {
      sellingPlan: ISmartrrSellingPlan;
      purchasables: IPurchasable[];
    }
  | undefined {
  if (!sellingPlanId) {
    return;
  }
  return findEligibleProductsForSmartrrSellingPlanId(purchasables, sellingPlanGroupsByProductId, sellingPlanId);
}

export function getPlanAndEligibleVariantsForSellingPlanId(
  purchasable: IPurchasable | undefined,
  variants: IPurchasableVariant[],
  sellingPlanId: string | undefined,
  sellingPlanGroupsByVariantId: { [id: string]: IShopifyLiquidSellingPlanGroup[] },
  sellingPlanGroupsByProductId: { [id: string]: IShopifyLiquidSellingPlanGroup[] }
): { sellingPlan: IShopifyLiquidSellingPlan; variants: IPurchasableVariant[] } | undefined {
  return findEligibleVariantsForSellingPlanId(
    purchasable,
    variants,
    sellingPlanGroupsByProductId,
    sellingPlanGroupsByVariantId,
    sellingPlanId
  );
}

// Do not use directly
export function findEligibleProductsForSellingPlanId(
  purchasables: IPurchasable[],
  sellingPlanGroupsByProductId: { [productId: string]: IShopifyLiquidSellingPlanGroup[] },
  sellingPlanId: string
) {
  if (!sellingPlanId) {
    return;
  }

  let plan: IShopifyLiquidSellingPlan | undefined;
  const eligiblePurchasables: IPurchasable[] = [];

  for (const purchasable of purchasables) {
    if (!purchasable.shopifyId) {
      continue;
    }
    const sellingPlanGroupsForProduct = sellingPlanGroupsByProductId[shopifyGidToNumber(purchasable.shopifyId)];
    const productSellingPlans = flatten((sellingPlanGroupsForProduct || []).map(g => g.selling_plans));

    const foundPlan = productSellingPlans.find(plan => plan.id === shopifyGidToNumber(sellingPlanId));
    if (foundPlan) {
      plan = foundPlan;
      eligiblePurchasables.push(purchasable);
    }
  }

  if (!plan) {
    return undefined;
  }

  return {
    sellingPlan: plan,
    purchasables: eligiblePurchasables,
  };
}

export function findEligibleProductsForSmartrrSellingPlanId(
  purchasables: IPurchasable[],
  sellingPlanGroupsByProductId: { [productId: string]: ISmartrrSellingPlanGroup[] },
  sellingPlanId: string
) {
  if (!sellingPlanId) {
    return;
  }

  let plan: ISmartrrSellingPlan | undefined;
  const eligiblePurchasables: IPurchasable[] = [];

  for (const purchasable of purchasables) {
    if (!purchasable.shopifyId) {
      continue;
    }
    const sellingPlanGroupsForProduct = sellingPlanGroupsByProductId[shopifyGidToNumber(purchasable.shopifyId)];
    const productSellingPlans = flatten((sellingPlanGroupsForProduct || []).map(g => g.sellingPlans));

    const foundPlan = productSellingPlans.find(
      plan => plan.shopifyNumericId === shopifyGidToNumber(sellingPlanId)
    );
    if (foundPlan) {
      plan = foundPlan;
      eligiblePurchasables.push(purchasable);
    }
  }

  if (!plan) {
    return undefined;
  }

  return {
    sellingPlan: plan,
    purchasables: eligiblePurchasables,
  };
}

// Do not use directly, instead use one of the `useSellingPlan` hooks
export function findEligibleVariantsForSellingPlanId(
  purchasable: IPurchasable | undefined,
  variants: IPurchasableVariant[],
  sellingPlanGroupsByProductId: { [productId: string]: IShopifyLiquidSellingPlanGroup[] },
  sellingPlanGroupsByVariantId: { [variantId: string]: IShopifyLiquidSellingPlanGroup[] },
  sellingPlanId: string | undefined
) {
  if (!sellingPlanId) {
    return undefined;
  }

  let plan: IShopifyLiquidSellingPlan | undefined;

  const sellingPlanGroupsForProduct = purchasable?.shopifyId
    ? sellingPlanGroupsByProductId[shopifyGidToNumber(purchasable.shopifyId)]
    : [];
  const productSellingPlans = flatten((sellingPlanGroupsForProduct || []).map(g => g.selling_plans));

  const foundPlan = productSellingPlans.find(plan => plan.id === shopifyGidToNumber(sellingPlanId));
  if (foundPlan) {
    // all variants are eligible if product has selling plan
    return { sellingPlan: foundPlan, variants };
  }

  const eligibleVariants: IPurchasableVariant[] = [];

  for (const variant of variants) {
    if (!variant.shopifyId) {
      continue;
    }

    const sellingPlanGroupsForVariant = sellingPlanGroupsByVariantId[shopifyGidToNumber(variant.shopifyId)];
    const variantSellingPlans = flatten((sellingPlanGroupsForVariant || []).map(g => g.selling_plans));
    const foundPlan = variantSellingPlans.find(plan => plan.id === shopifyGidToNumber(sellingPlanId));
    if (foundPlan) {
      plan = foundPlan;
      eligibleVariants.push(variant);
    }
  }

  if (!plan) {
    return undefined;
  }

  return { sellingPlan: plan, variants: eligibleVariants };
}

export function findEligibleVariantsForSmartrrSellingPlanId(
  purchasable: IPurchasable | undefined,
  variants: IPurchasableVariant[],
  sellingPlanGroupsByProductId: { [productId: string]: ISmartrrSellingPlanGroup[] },
  sellingPlanGroupsByVariantId: { [variantId: string]: ISmartrrSellingPlanGroup[] },
  sellingPlanId: string | undefined
) {
  if (!sellingPlanId) {
    return undefined;
  }

  let plan: ISmartrrSellingPlan | undefined;

  const sellingPlanGroupsForProduct = purchasable?.shopifyId
    ? sellingPlanGroupsByProductId[shopifyGidToNumber(purchasable.shopifyId)]
    : [];
  const productSellingPlans = flatten((sellingPlanGroupsForProduct || []).map(g => g.sellingPlans));

  const foundPlan = productSellingPlans.find(plan => plan.shopifyNumericId === shopifyGidToNumber(sellingPlanId));
  if (foundPlan) {
    // all variants are eligible if product has selling plan
    return { sellingPlan: foundPlan, variants };
  }

  const eligibleVariants: IPurchasableVariant[] = [];

  for (const variant of variants) {
    if (!variant.shopifyId) {
      continue;
    }

    const sellingPlanGroupsForVariant = sellingPlanGroupsByVariantId[shopifyGidToNumber(variant.shopifyId)];
    const variantSellingPlans = flatten((sellingPlanGroupsForVariant || []).map(g => g.sellingPlans));
    const foundPlan = variantSellingPlans.find(
      plan => plan.shopifyNumericId === shopifyGidToNumber(sellingPlanId)
    );
    if (foundPlan) {
      plan = foundPlan;
      eligibleVariants.push(variant);
    }
  }

  if (!plan) {
    return undefined;
  }

  return { sellingPlan: plan, variants: eligibleVariants };
}

export function getSellingPlanToProductAndVariantMapsFromSellingPlanGroups(
  sellingPlanGroups: (ISmartrrSellingPlanGroup | ISmartrrAddOnsConfig)[]
) {
  const sellingPlanGroupsByProductId: { [id: number]: IShopifyLiquidSellingPlanGroup[] } = {};
  const sellingPlanGroupsByVariantId: { [id: number]: IShopifyLiquidSellingPlanGroup[] } = {};

  for (const sellingPlanGroup of sellingPlanGroups) {
    const { sellingPlans, productIds, variantIds } = sellingPlanGroup;

    for (const productId of productIds || []) {
      if (!sellingPlanGroupsByProductId[shopifyGidToNumber(productId)]) {
        sellingPlanGroupsByProductId[shopifyGidToNumber(productId)] = [];
      }
      sellingPlanGroupsByProductId[shopifyGidToNumber(productId)].push(
        formatSellingPlanGroupFromQueryToLiquid(sellingPlanGroup, sellingPlans || [])
      );
    }
    for (const variantId of variantIds || []) {
      if (!sellingPlanGroupsByVariantId[shopifyGidToNumber(variantId)]) {
        sellingPlanGroupsByVariantId[shopifyGidToNumber(variantId)] = [];
      }
      sellingPlanGroupsByVariantId[shopifyGidToNumber(variantId)].push(
        formatSellingPlanGroupFromQueryToLiquid(sellingPlanGroup, sellingPlans || [])
      );
    }
  }

  return {
    sellingPlanGroupsByProductId,
    sellingPlanGroupsByVariantId,
  };
}

export function getSmartrrSellingPlanToProductAndVariantMapsFromSellingPlanGroups(
  sellingPlanGroups: ISmartrrSellingPlanGroup[]
) {
  const sellingPlanGroupsByProductId: { [id: number]: ISmartrrSellingPlanGroup[] } = {};
  const sellingPlanGroupsByVariantId: { [id: number]: ISmartrrSellingPlanGroup[] } = {};

  for (const sellingPlanGroup of sellingPlanGroups) {
    const { productIds, variantIds } = sellingPlanGroup;

    for (const productId of productIds || []) {
      const gid = shopifyGidToNumber(productId);
      const arr = sellingPlanGroupsByProductId[gid];

      if (arr && arr.find(g => g.shopifyId === sellingPlanGroup.shopifyId) === undefined) {
        arr.push(sellingPlanGroup);
      }
      if (!arr) {
        sellingPlanGroupsByProductId[gid] = [sellingPlanGroup];
      }
    }
    for (const variantId of variantIds || []) {
      const gid = shopifyGidToNumber(variantId);
      const arr = sellingPlanGroupsByVariantId[gid];

      if (arr && arr.find(g => g.shopifyId === sellingPlanGroup.shopifyId) === undefined) {
        arr.push(sellingPlanGroup);
      }
      if (!arr) {
        sellingPlanGroupsByVariantId[gid] = [sellingPlanGroup];
      }
    }
  }

  return {
    sellingPlanGroupsByProductId,
    sellingPlanGroupsByVariantId,
  };
}

export function formatSellingPlanGroupFromQueryToLiquid(
  sellingPlanGroup: ISmartrrSellingPlanGroup | ISmartrrAddOnsConfig,
  sellingPlans: (ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan)[]
): IShopifyLiquidSellingPlanGroup {
  return {
    id: `${sellingPlanGroup.shopifyNumericId}`,
    app_id: sellingPlanGroup.appId || null,
    name: sellingPlanGroup.name,
    selling_plans: sellingPlans.map(formatSellingPlanFromQueryToLiquid),
    options: sellingPlanGroup.options.map(option => ({
      name: option,
    })),
  };
}

export function formatSellingPlanFromQueryToLiquid(
  plan: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan
): IShopifyLiquidSellingPlan {
  return {
    description: (plan as ISmartrrSellingPlan).description || "",
    id: shopifyGidToNumber(plan.shopifyNumericId),
    name: (plan as ISmartrrSellingPlan).name || "",
    options: ((plan as ISmartrrSellingPlan).options || []).map(option => ({
      name: option,
    })),
    recurring_deliveries: (plan as ISmartrrSellingPlan).recurringDeliveries || false,
    price_adjustments: (
      plan.pricingPolicies as (SmartrrSellingPlanPricingPolicy | SmartrrSellingPlanFixedPricingPolicy)[]
    ).map(({ adjustmentValue, adjustmentType }) => {
      switch (adjustmentType) {
        case SellingPlanPricingPolicyAdjustmentType.Percentage: {
          return {
            value_type: "percentage",
            value: (adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypePercentage["adjustmentValue"])
              .percentage,
          };
        }
        case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
          return {
            value_type: "fixed_amount",
            value: (
              adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypeFixedAmount["adjustmentValue"]
            ).amount as number,
          };
        }
        case SellingPlanPricingPolicyAdjustmentType.Price: {
          return {
            value_type: "price",
            value: (adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypePrice["adjustmentValue"])
              .amount as number,
          };
        }

        default: {
          throw new Error("Invalid adjustment type");
        }
      }
    }),
  };
}

export function formatSellingPlanFromShopifyQueryToSmartrrLiquid(
  plan: ISmartrrSellingPlan | ISmartrrAddOnsConfigSellingPlan,
  currency: CurrencyCode = "USD"
): IShopifyLiquidSellingPlan {
  return {
    description: (plan as ISmartrrSellingPlan).description || "",
    id: shopifyGidToNumber(plan.shopifyNumericId),
    name: (plan as ISmartrrSellingPlan).name || "",
    options: ((plan as ISmartrrSellingPlan).options || []).map(option => ({
      name: option,
    })),
    recurring_deliveries: (plan as ISmartrrSellingPlan).recurringDeliveries || false,
    price_adjustments: (
      plan.pricingPolicies as (SmartrrSellingPlanPricingPolicy | SmartrrSellingPlanFixedPricingPolicy)[]
    ).map(({ adjustmentValue, adjustmentType }) => {
      switch (adjustmentType) {
        case SellingPlanPricingPolicyAdjustmentType.Percentage: {
          return {
            value_type: "percentage",
            value: (adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypePercentage["adjustmentValue"])
              .percentage,
          };
        }
        case SellingPlanPricingPolicyAdjustmentType.FixedAmount: {
          return {
            value_type: "fixed_amount",
            value: unformatMoney(
              (adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypeFixedAmount["adjustmentValue"])
                .amount as number,
              currency
            ),
          };
        }
        case SellingPlanPricingPolicyAdjustmentType.Price: {
          return {
            value_type: "price",
            value: unformatMoney(
              (adjustmentValue as SmartrrSellingPlanPricingPolicyAdjustmentTypePrice["adjustmentValue"])
                .amount as number,
              currency
            ),
          };
        }

        default: {
          throw new Error("Invalid adjustment type");
        }
      }
    }),
  };
}

export function hasDuplicates(list: string[]): boolean {
  return uniq(list).length !== list.length;
}

/**
 * Recursively checks validationErrors for the first _errors
 * property with a non-empty array. If none found or there
 * are multiple errors then returns null.
 */
export function handleValidationErrors<T extends Record<string, any>>(validationErrors: T | null): string | null {
  if (!validationErrors) {
    return null;
  }
  const foundErrors: string[] = [];

  function findErrors(obj: T): string | null {
    if (obj?._errors?.length) {
      for (const error of obj._errors) {
        if (!foundErrors.includes(error)) {
          foundErrors.push(error);
        }
      }
    }

    const keys = Object.keys(obj).filter(key => key !== "_errors");

    if (keys.length > 1) {
      return null;
    }

    for (const key of keys) {
      const nestedObj = obj[key];

      if (nestedObj) {
        const error = findErrors(nestedObj);

        if (error && !foundErrors.includes(error)) {
          foundErrors.push(error);
        }
      }
    }

    return foundErrors.length === 1 ? foundErrors[0] : null;
  }
  return findErrors(validationErrors);
}
