import {
  ActionList,
  Button,
  Checkbox,
  IndexTable,
  LegacyCard,
  LegacyStack,
  Pagination,
  PaginationProps,
  Popover,
  Select,
  SelectGroup,
  SelectOption,
  Text,
} from "@shopify/polaris";
import {
  IndexTableHeading,
  IndexTableProps,
  RowProps,
} from "@shopify/polaris/build/ts/src/components/IndexTable";
import { ActionListItemDescriptor, NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import {
  ArrowDownMinor,
  ArrowUpMinor,
  CancelSmallMinor,
  ColumnWithTextMajor,
  FilterMajor,
  SortMinor,
} from "@shopify/polaris-icons";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { SmartrrFilterType, SmartrrSortType } from "@smartrr/shared/utils/paginatedQuery";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import {
  CPSTransactionHistoryTableColumnKeyType,
  TransactionHistoryParsedDataType,
  TransactionHistoryTableColumnKeyType,
} from "@vendor-app/app/AdminRoute/AdminBillsRoute/libs";
import {
  CPSOrderHistoryTableColumnKeyType,
  OrderHistoryParsedDataType,
  OrderHistoryTableColumnKeyType,
} from "@vendor-app/app/AdminRoute/AdminOrdersRoute/libs";
import {
  CPSLineItemsParsedDataType,
  CPSLineItemsTableColumnKeyType,
  SelectBoldText,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import {
  CPSUpcomingOrdersParsedDataType,
  CPSUpcomingOrdersTableColumnKeyType,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/SubscriptionDetails/components/OrdersAndEventData/UpcomingOrders";

import "./index.css";

interface TypedTableCellProps {
  hideCell?: boolean;
  children?: ReactNode;
  className?: string;
}
type TypedTableRowProps = RowProps;

export interface TypedTableColumnValue {
  name: string;
  filtering: boolean;
  sorting: boolean;
  paginatedValue: string;
  disabled: boolean;
}

export enum SelectionType {
  All = "all",
  Page = "page",
  Multi = "multi",
  Single = "single",
}

export type TypedTableColumnType<T extends string> = {
  [key in T]: TypedTableColumnValue;
};

export type Range = [number, number];

export type TypedTableDataType<T> = T extends TransactionHistoryTableColumnKeyType
  ? TransactionHistoryParsedDataType
  : T extends CPSTransactionHistoryTableColumnKeyType
    ? TransactionHistoryParsedDataType
    : T extends CPSUpcomingOrdersTableColumnKeyType
      ? CPSUpcomingOrdersParsedDataType
      : T extends OrderHistoryTableColumnKeyType
        ? OrderHistoryParsedDataType
        : T extends CPSOrderHistoryTableColumnKeyType
          ? OrderHistoryParsedDataType
          : T extends CPSLineItemsTableColumnKeyType
            ? CPSLineItemsParsedDataType
            : any;

interface TypedTableProps<T extends string> extends Omit<IndexTableProps, "headings" | "emptyState"> {
  data: TypedTableDataType<T>[];
  columns: TypedTableColumnType<T>;
  emptyStateText: string | undefined;
  onRowClick?: (item: TypedTableDataType<T>, index: number) => void;
  isRowSelected?: (id: string) => boolean | undefined;
}

type Action = "delete" | "add" | "update" | "deleteAll";

interface TypedNewFilterProps<T extends string> {
  columns: TypedTableColumnType<T>;
  filter: SmartrrFilterType;
  filterComponent: (
    field: string,
    value: string | string[] | undefined,
    handleSetFilter: (action: Action, key: string, value?: string | string[], prevKey?: string) => void
  ) => JSX.Element;

  setFilter: (filter: SmartrrFilterType) => void;
}

interface TypedSortingProps<T extends string> {
  columns: TypedTableColumnType<T>;
  orderByField: string;
  orderByValue: string;
  setOrderByField: (value: string) => void;
  setOrderByValue: (value: SmartrrSortType) => void;
}

interface TypedColumnsProps<T extends string> {
  columns: TypedTableColumnType<T>;
  addToast: (text: string) => void;
  setDisabledColumns: (columns: TypedTableColumnType<T>) => void;
}

function TypedTableRow(tableRowProps: TypedTableRowProps) {
  return <IndexTable.Row {...tableRowProps} />;
}

function TypedTableCell(tableCellProps: TypedTableCellProps) {
  if (tableCellProps.hideCell) {
    return <React.Fragment></React.Fragment>;
  }
  return <IndexTable.Cell {...tableCellProps} />;
}

const FILTER_NO_KEY = "default";

export const SortingTypeContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;

    .Polaris-Button__Content {
      color: #2c6ecb;

      .Polaris-Icon__Svg {
        fill: #2c6ecb;
      }
    }
  }
`;

const FilterContainerGrid = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 178px 178px 20px;
  justify-content: space-between;
`;

export const SortContainer = styled.div`
  overflow: hidden;

  .Polaris-LegacyCard__Section {
    padding: 8px !important;
  }

  & .Polaris-Button {
    justify-content: flex-start;
    margin: 0 !important;
    width: 100%;

    .Polaris-Button__Content {
      justify-content: flex-start;
    }
  }
`;

export const SortingValueContainer = styled.div`
  .Polaris-Button--pressed {
    background-color: #f6f6f7;
    border: none;
    box-shadow: none;
    color: #202223;
    padding: var(--p-space-2);
  }
`;

export const NoUpcomingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 160px;
  justify-content: center;
`;

export const IdContainer = styled.div`
  color: #2c6ecb;
  cursor: pointer;
`;

const FilterValueButtonContainer = styled.div`
  & > .Polaris-Button {
    width: 100%;
  }

  .Polaris-Button__Content {
    justify-content: space-between;
  }
`;

export function usePolarisTypedTable<T extends string>() {
  return useMemo(
    () => ({
      Table: React.memo(
        ({
          data,
          columns,
          emptyStateText,
          onRowClick,
          isRowSelected,
          ...typedTableProps
        }: TypedTableProps<T>) => {
          const columnEntries = Object.entries<TypedTableColumnValue>(columns);

          return (
            <IndexTable
              sortable={Object.values<TypedTableColumnValue>(columns).map(v => !v.disabled && v.sorting)}
              headings={
                columnEntries
                  .filter(([key, column]) => !column.disabled)
                  .map(([key, column]) => ({
                    id: `table-column-${key}`,
                    title: column.name,
                  })) as NonEmptyArray<IndexTableHeading>
              }
              emptyState={
                <NoUpcomingWrapper>
                  <Text variant="bodyLg" as="span" color="subdued">
                    {emptyStateText ?? "No resources found"}
                  </Text>
                </NoUpcomingWrapper>
              }
              {...typedTableProps}
            >
              {data.map((item, index) => {
                return (
                  <TypedTableRow
                    id={String(item.id)}
                    key={index}
                    position={index}
                    selected={isRowSelected?.(item.id)}
                    onClick={onRowClick ? () => onRowClick(item, index) : NO_OP_CALLBACK}
                  >
                    {/* TODO: make key type to be T or union from parent component */}
                    {columnEntries.map(([key, column]) => {
                      return (
                        <TypedTableCell
                          className={`Polaris-IndexTable__TableCell-${key}`}
                          key={key}
                          hideCell={column.disabled}
                        >
                          {item[key]}
                        </TypedTableCell>
                      );
                    })}
                  </TypedTableRow>
                );
              })}
            </IndexTable>
          );
        }
      ),
      Row: React.memo(({ children, ...typedRowProps }: TypedTableRowProps) => {
        return <TypedTableRow {...typedRowProps}>{children}</TypedTableRow>;
      }),
      Cell: React.memo(({ children, ...typedCellProps }: TypedTableCellProps) => {
        return <TypedTableCell {...typedCellProps}>{children}</TypedTableCell>;
      }),
      Pagination: React.memo((tablePaginationProps: PaginationProps) => {
        return (
          <div className="polaris-pagination-wrapper">
            <Pagination {...tablePaginationProps} />
          </div>
        );
      }),
      Columns: React.memo(({ columns, setDisabledColumns, addToast }: TypedColumnsProps<T>) => {
        const [openColumnsFilter, setOpenColumnsFilter] = useState<boolean>(false);

        return (
          <Popover
            active={openColumnsFilter}
            activator={
              <Button icon={ColumnWithTextMajor} onClick={() => setOpenColumnsFilter(open => !open)}>
                Columns
              </Button>
            }
            onClose={() => setOpenColumnsFilter(false)}
          >
            <LegacyCard sectioned>
              <LegacyStack vertical spacing="extraTight">
                {Object.entries<TypedTableColumnValue>(columns).map(([key, column]) => (
                  <Checkbox
                    key={`columns-${key}`}
                    label={column.name}
                    checked={!column.disabled}
                    onChange={value => {
                      if (
                        Object.values<TypedTableColumnValue>(columns).filter(value => !value.disabled).length ===
                          1 &&
                        !value
                      ) {
                        addToast("Please select at least one column");
                      } else {
                        const newColumns = { ...columns };
                        newColumns[key as T].disabled = !column.disabled;
                        setDisabledColumns(newColumns);
                      }
                    }}
                  />
                ))}
              </LegacyStack>
            </LegacyCard>
          </Popover>
        );
      }),
      Sorting: React.memo(
        ({ columns, orderByValue, orderByField, setOrderByValue, setOrderByField }: TypedSortingProps<T>) => {
          const [openSortingPopover, setOpenSortingPopover] = useState<boolean>(false);

          return (
            <Popover
              active={openSortingPopover}
              onClose={() => setOpenSortingPopover(false)}
              activator={
                <Button onClick={() => setOpenSortingPopover(open => !open)} icon={SortMinor}>
                  Sort
                </Button>
              }
            >
              <SortContainer>
                <LegacyCard>
                  <LegacyCard.Section>
                    <SortingValueContainer>
                      <LegacyStack vertical spacing="extraTight">
                        {Object.entries<TypedTableColumnValue>(columns)
                          .filter(([key, c]) => c.sorting)
                          .map(([key, column], index) => {
                            return (
                              <Button
                                key={`$sort-btn-${index}`}
                                plain={orderByField !== column.paginatedValue}
                                removeUnderline
                                pressed={orderByField === column.paginatedValue}
                                monochrome
                                onClick={() => setOrderByField(column.paginatedValue)}
                              >
                                {column.name}
                              </Button>
                            );
                          })}
                      </LegacyStack>
                    </SortingValueContainer>
                  </LegacyCard.Section>
                  <LegacyCard.Section>
                    <SortingTypeContainer>
                      <LegacyStack vertical spacing="extraTight">
                        <Button
                          plain
                          monochrome={orderByValue !== "ASC"}
                          removeUnderline
                          pressed={orderByValue === "ASC"}
                          icon={ArrowUpMinor}
                          onClick={() => setOrderByValue("ASC")}
                        >
                          Ascending
                        </Button>
                        <Button
                          plain
                          removeUnderline
                          pressed={orderByValue === "DESC"}
                          monochrome={orderByValue !== "DESC"}
                          icon={ArrowDownMinor}
                          onClick={() => setOrderByValue("DESC")}
                        >
                          Descending
                        </Button>
                      </LegacyStack>
                    </SortingTypeContainer>
                  </LegacyCard.Section>
                </LegacyCard>
              </SortContainer>
            </Popover>
          );
        }
      ),
      Filter: React.memo(({ columns, filter, setFilter, filterComponent }: TypedNewFilterProps<T>) => {
        const [openFiltersPopover, setOpenFiltersPopover] = useState<boolean>(false);
        const [openSubfilterPopover, setOpenSubfilterPopover] = useState<string | undefined>();
        const [addFilterPopover, setAddFilterPopover] = useState(false);

        const firstKey =
          Object.values<TypedTableColumnValue>(columns).find(c => !c.disabled)?.paginatedValue ?? "";

        const handleSetFilter = (action: Action, key: string, value?: string | string[], prevKey?: string) => {
          let parsedFilter = { ...filter };
          if (action === "deleteAll") {
            parsedFilter = {};
          }
          if (action === "delete") {
            delete parsedFilter[key];
          }
          if (action === "add") {
            parsedFilter[key] = value;
          }
          if (action === "update") {
            if (prevKey) {
              delete parsedFilter[prevKey];
              parsedFilter[key] = value;
            }
            parsedFilter[key] = value;
          }

          setFilter(parsedFilter);
        };

        const filterKeys = useMemo(() => {
          return Object.keys(filter);
        }, [filter, columns]);

        const getSelectOptions = useCallback((): (SelectOption | SelectGroup)[] => {
          const selectOptions = Object.values<TypedTableColumnValue>(columns)
            .filter(column => column.filtering)
            .map(column => ({
              label: column.name,
              value: column.paginatedValue,
              disabled: filterKeys.includes(column.paginatedValue),
            }));

          selectOptions.unshift({
            label: "Select a filter",
            disabled: true,
            value: FILTER_NO_KEY,
          });

          return selectOptions;
        }, [columns, filterKeys]);

        const getListOptions = useCallback((): ActionListItemDescriptor[] => {
          return Object.values<TypedTableColumnValue>(columns)
            .filter(column => column.filtering)
            .map(column => ({
              content: column.name,
              disabled: filterKeys.includes(column.paginatedValue),
              onAction() {
                handleSetFilter("add", column.paginatedValue, undefined);
                setAddFilterPopover(false);
              },
            }));
        }, [columns, filterKeys]);

        const FilterFields = ({
          filter: key,
          value,
        }: {
          filter: string;
          value: string | string[] | undefined;
        }) => {
          return (
            <FilterContainerGrid key={`filter-field-${key}`}>
              <SelectBoldText>
                <Select
                  label=""
                  labelHidden
                  value={key}
                  options={getSelectOptions()}
                  onChange={value => {
                    handleSetFilter("update", value, undefined, key);
                  }}
                />
              </SelectBoldText>
              <Popover
                preventCloseOnChildOverlayClick
                autofocusTarget="first-node"
                active={openSubfilterPopover === key}
                onClose={() => {
                  setOpenSubfilterPopover(undefined);
                }}
                activator={
                  <FilterValueButtonContainer>
                    <Button
                      disabled={key === FILTER_NO_KEY}
                      disclosure="select"
                      onClick={() => setOpenSubfilterPopover(k => (k === key ? undefined : key))}
                    >
                      Select a value
                    </Button>
                  </FilterValueButtonContainer>
                }
              >
                <LegacyCard sectioned>{filterComponent(key, value, handleSetFilter)}</LegacyCard>
              </Popover>
              {key === FILTER_NO_KEY ? null : (
                <Button
                  plain
                  onClick={() => {
                    handleSetFilter("delete", key);
                    setOpenSubfilterPopover(undefined);
                  }}
                  icon={CancelSmallMinor}
                />
              )}
            </FilterContainerGrid>
          );
        };

        return (
          <Popover
            fluidContent
            preventCloseOnChildOverlayClick
            active={openFiltersPopover}
            activator={
              <Button icon={FilterMajor} onClick={() => setOpenFiltersPopover(open => !open)}>
                Filters
              </Button>
            }
            onClose={() => {
              setOpenFiltersPopover(false);
            }}
          >
            <LegacyCard sectioned>
              <LegacyStack vertical spacing="tight">
                {!filterKeys.length && (
                  <LegacyStack vertical spacing="tight">
                    <Text variant="bodyMd" as="span" color="subdued">
                      No filters applied to this view yet
                    </Text>
                    <FilterFields filter={FILTER_NO_KEY} value={undefined} />
                  </LegacyStack>
                )}
                {filterKeys.map(key => {
                  const value = filter[key];

                  return <FilterFields key={key} filter={key} value={value} />;
                })}
                {filterKeys.length && filterKeys[0] !== FILTER_NO_KEY ? (
                  <LegacyStack distribution="equalSpacing" alignment="center">
                    <Popover
                      active={addFilterPopover}
                      preferredPosition="above"
                      onClose={() => setAddFilterPopover(false)}
                      activator={
                        <Button
                          plain
                          onClick={() => {
                            setAddFilterPopover(true);
                            setOpenSubfilterPopover(undefined);
                          }}
                        >
                          Add another filter
                        </Button>
                      }
                    >
                      <ActionList actionRole="menuitem" items={getListOptions()} />
                    </Popover>
                    {filterKeys.length ? (
                      <Button
                        plain
                        onClick={() => {
                          handleSetFilter("deleteAll", firstKey);
                          setOpenSubfilterPopover(undefined);
                        }}
                      >
                        Clear
                      </Button>
                    ) : null}
                  </LegacyStack>
                ) : null}
              </LegacyStack>
            </LegacyCard>
          </Popover>
        );
      }),
    }),
    []
  );
}
