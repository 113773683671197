import { RRule, Frequency as RRuleFrequency } from "rrule";

import { PeriodUnit } from "../../Period";
import { nowUTC, toUTCDate } from "../../utils/dateUtils";

export interface IRRuleManagerEventFeedbackConfig {
  extendOnUserSkip: boolean;
  extendOnHiddenSkip: boolean;
  ignorePastIndicesWhenExtending: boolean;
}

export type SkipArrValue = ISkip | null;

export interface ISkip {
  userSkipped: boolean;
  hiddenSkipped: boolean;
}

interface IStringifiableRRuleManagerFields extends IRRuleManagerEventFeedbackConfig {
  initialSequenceStartDate: Date;
  billedCycleStartDate?: Date;
  billedDeliveriesCount?: number;
  deliveryFrequencyMultiple: number;
  paymentFrequencyMultiple: number;
  totalOrdersCount?: number;
  totalBillingCycles?: number;
  minCycles?: number | null;
  maxCycles?: number | null;
  orderCycleIndex?: number;
  skips: SkipArrValue[];
}

export interface IRRuleManagerJson extends IStringifiableRRuleManagerFields {
  deliveryRRuleString: string;
}

interface RRuleManagerFields extends IStringifiableRRuleManagerFields {
  deliveryRRule: Readonly<RRule>;
}

const rruleFrequencyToPeriodUnitsMap: {
  [key in RRuleFrequency]: PeriodUnit | null;
} = {
  [RRuleFrequency.YEARLY]: "year",
  [RRuleFrequency.MONTHLY]: "month",
  [RRuleFrequency.WEEKLY]: "week",
  [RRuleFrequency.DAILY]: "day",
  [RRuleFrequency.HOURLY]: null,
  [RRuleFrequency.MINUTELY]: null,
  [RRuleFrequency.SECONDLY]: null,
};
const periodUnitsToRRuleFrequencyMap: {
  [key in PeriodUnit]: RRuleFrequency;
} = {
  year: RRuleFrequency.YEARLY,
  month: RRuleFrequency.MONTHLY,
  week: RRuleFrequency.WEEKLY,
  day: RRuleFrequency.DAILY,
};

export function rruleFrequencyToPeriodUnits(freq: RRuleFrequency): PeriodUnit {
  const periodUnit = rruleFrequencyToPeriodUnitsMap[freq];
  if (!periodUnit) {
    throw new Error(`RRule frequency unit (${freq}) does not map to Smartrr frequency unit`);
  }

  return periodUnit;
}

export function periodUnitsToRRuleFrequency(periodUnit: PeriodUnit): RRuleFrequency {
  return periodUnitsToRRuleFrequencyMap[periodUnit];
}

export interface IScheduleConfig {
  deliveryFrequencyValue: number;
  paymentFrequencyValue: number;
  frequencyUnit: PeriodUnit;
  deliveryUntilDate?: Date;
  deliveryUntilCount?: number;
  minCycles?: number | null;
  maxCycles?: number | null;
  orderCycleIndex?: number;
}

/**
 * Data structure that stores all fields from ScheduleOrm and can be used both
 * on front-end and back-end for schedule representation.
 */
export abstract class RRuleManagerStructure implements RRuleManagerFields {
  readonly nowUponCreation: Date = nowUTC();

  readonly initialSequenceStartDate: Date;

  /**
   * The date of the last billing cycle that was billed for the subscription.
   */
  readonly billedCycleStartDate: Date | undefined;

  /**
   * The number of deliveries that was paid by the last bill.
   */
  readonly billedDeliveriesCount: number | undefined;

  /**
   * The total number of orders that have occurred since the start of the
   * subscription.
   */
  readonly totalOrdersCount: number | undefined;

  /**
   * The total number of billing cycles that have occurred since the start of
   * the subscription.
   */
  readonly totalBillingCycles: number | undefined;

  readonly minCycles?: number | null;

  readonly maxCycles?: number | null;

  readonly extendOnUserSkip: boolean;

  readonly extendOnHiddenSkip: boolean;

  readonly ignorePastIndicesWhenExtending: boolean;

  /**
   * The number of schedule events between deliveries. Always as 1, so ignore it.
   */
  readonly deliveryFrequencyMultiple: number;

  /**
   * number of schedule events between billing cycles. If it is 1, then we are
   * dealing with a regular pay-as-you-go subscription. If it is more than 1,
   * then we are dealing with prepaid subscription.
   */
  readonly paymentFrequencyMultiple: number;

  /**
   * schedule rule that generates schedule events.
   */
  readonly deliveryRRule: Readonly<RRule>;

  /**
   * Whether schedule events were skipped by user or by the system. Indexes
   * in this array are matching to indexFromScheduleStart of generated schedule
   * events.
   */
  readonly skips: SkipArrValue[];

  readonly orderCycleIndex?: number;

  protected readonly vendorSchedule: Readonly<RRule>;

  readonly prevRRuleManager?: RRuleManagerStructure;

  // hiding constructor since it corresponds to a specific input
  protected constructor(
    rruleManagerJson: IRRuleManagerJson,
    vendorSchedule: RRule | Readonly<RRule>,
    useStartDateAsNow = false,
    prevRRuleManager?: RRuleManagerStructure
  ) {
    this.vendorSchedule = Object.freeze(vendorSchedule);

    // Although dates in IRRuleManagerJson are typed as dates, in reality they are serialized as strings,
    // so we need to parse them from ISO strings, same way as in ScheduleOrm
    this.initialSequenceStartDate = toUTCDate(rruleManagerJson.initialSequenceStartDate);
    this.billedCycleStartDate = rruleManagerJson.billedCycleStartDate
      ? toUTCDate(rruleManagerJson.billedCycleStartDate)
      : undefined;

    this.billedDeliveriesCount = rruleManagerJson.billedDeliveriesCount;
    this.extendOnUserSkip = rruleManagerJson.extendOnUserSkip;
    this.extendOnHiddenSkip = rruleManagerJson.extendOnHiddenSkip;
    this.ignorePastIndicesWhenExtending = rruleManagerJson.ignorePastIndicesWhenExtending;
    this.deliveryFrequencyMultiple = rruleManagerJson.deliveryFrequencyMultiple;
    this.paymentFrequencyMultiple = rruleManagerJson.paymentFrequencyMultiple;
    this.totalOrdersCount = rruleManagerJson.totalOrdersCount;
    this.totalBillingCycles = rruleManagerJson.totalBillingCycles;
    this.minCycles = rruleManagerJson.minCycles;
    this.maxCycles = rruleManagerJson.maxCycles;
    this.orderCycleIndex = prevRRuleManager?.orderCycleIndex ?? rruleManagerJson.orderCycleIndex;

    // force start date to UTC time
    const rrule = RRule.fromString(rruleManagerJson.deliveryRRuleString);

    rrule.options.dtstart = rrule.options.dtstart ? toUTCDate(rrule.options.dtstart) : this.nowUponCreation;

    if (useStartDateAsNow) {
      this.nowUponCreation = this.initialSequenceStartDate;
    } else if (prevRRuleManager) {
      // if prevRRule manager provided then this is a copy, use that "now"
      this.nowUponCreation = prevRRuleManager.nowUponCreation;
    }

    this.deliveryRRule = Object.freeze(rrule);
    this.skips = rruleManagerJson.skips;

    if (prevRRuleManager) {
      this.prevRRuleManager = prevRRuleManager;
    }
  }

  protected get config(): RRuleManagerFields {
    return {
      initialSequenceStartDate: this.initialSequenceStartDate,
      billedCycleStartDate: this.billedCycleStartDate,
      billedDeliveriesCount: this.billedDeliveriesCount,
      extendOnUserSkip: this.extendOnUserSkip,
      extendOnHiddenSkip: this.extendOnHiddenSkip,
      ignorePastIndicesWhenExtending: this.ignorePastIndicesWhenExtending,
      deliveryFrequencyMultiple: this.deliveryFrequencyMultiple,
      paymentFrequencyMultiple: this.paymentFrequencyMultiple,
      totalOrdersCount: this.totalOrdersCount,
      totalBillingCycles: this.totalBillingCycles,
      minCycles: this.minCycles,
      maxCycles: this.maxCycles,
      deliveryRRule: this.deliveryRRule,
      skips: this.skips,
    };
  }

  toJson = (): IRRuleManagerJson => ({
    initialSequenceStartDate: this.initialSequenceStartDate,
    billedCycleStartDate: this.billedCycleStartDate,
    billedDeliveriesCount: this.billedDeliveriesCount,
    extendOnUserSkip: this.extendOnUserSkip,
    extendOnHiddenSkip: this.extendOnHiddenSkip,
    ignorePastIndicesWhenExtending: this.ignorePastIndicesWhenExtending,
    deliveryFrequencyMultiple: this.deliveryFrequencyMultiple,
    paymentFrequencyMultiple: this.paymentFrequencyMultiple,
    totalOrdersCount: this.totalOrdersCount,
    totalBillingCycles: this.totalBillingCycles,
    minCycles: this.minCycles,
    maxCycles: this.maxCycles,
    orderCycleIndex: this.orderCycleIndex,
    deliveryRRuleString: this.deliveryRRule.toString(),
    skips: this.skips,
  });

  getScheduleConfig = (): IScheduleConfig => ({
    deliveryFrequencyValue: this.deliveryRRule.options.interval,
    paymentFrequencyValue: this.deliveryRRule.options.interval * this.paymentFrequencyMultiple,
    frequencyUnit: rruleFrequencyToPeriodUnits(this.deliveryRRule.options.freq),
    deliveryUntilDate: this.deliveryRRule.options.until || undefined,
    deliveryUntilCount: this.deliveryRRule.options.count || undefined,
  });
}
