import { DatePicker, Modal, Range, Text } from "@shopify/polaris";
import { areDatesOnSameDay } from "@smartrr/shared/utils/dateUtils";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { useDisabledDatesByBillingDays } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/hooks/useDisabledDatesByBillingDays";

export interface DatePickerModalProps {
  open: boolean;
  title: string;
  initialDate: Date;
  disabledDatesBefore?: Date;
  disabledDatesAfter?: Date;
  /**
   * @default Disabled billing days
   */
  disableSpecificDates?: Date[];
  bodyElement?: JSX.Element;
  helpText?: string;

  onConfirm(date: Date): void;
  onClose(): void;
}

export const DatePickerContainer = styled.div`
  .Polaris-DatePicker__Month thead .Polaris-DatePicker__Weekday {
    text-align: center !important;
  }

  .Polaris-Text--subdued {
    margin-top: 15px;
    text-align: center;
  }
`;

export function DatePickerModal({
  initialDate,
  open,
  onClose,
  onConfirm,
  disabledDatesBefore,
  disabledDatesAfter,
  disableSpecificDates,
  title,
  bodyElement,
  helpText,
}: DatePickerModalProps) {
  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const [{ month, year }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });

  const disabledDatesFromBillingDays = useDisabledDatesByBillingDays(new Date(year, month - 1, 1), [currentDate]);

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const showHelpText = helpText && areDatesOnSameDay(selectedDate, currentDate);

  const onDateChange = (range: Range) => {
    setSelectedDate(range.start);
  };

  return (
    <Modal
      title={title}
      open={open}
      onClose={onClose}
      primaryAction={{
        id: "set-next-order__confirm-btn",
        content: "Confirm",
        onAction: () => onConfirm(selectedDate!),
      }}
      secondaryActions={[
        {
          id: "set-next-order__cancel-btn",
          content: "Cancel",
          onAction: onClose,
        },
      ]}
    >
      {bodyElement ? <Modal.Section>{bodyElement}</Modal.Section> : <React.Fragment />}
      <Modal.Section>
        <DatePickerContainer>
          <DatePicker
            id="set-next-order__date-picker"
            allowRange={false}
            month={month}
            year={year}
            selected={selectedDate}
            disableDatesBefore={disabledDatesBefore}
            disableDatesAfter={disabledDatesAfter}
            disableSpecificDates={disableSpecificDates ?? disabledDatesFromBillingDays}
            onChange={range => onDateChange(range)}
            onMonthChange={handleMonthChange}
          />
          {showHelpText ? (
            <Text as="p" color="subdued">
              {helpText}
            </Text>
          ) : (
            <React.Fragment />
          )}
        </DatePickerContainer>
      </Modal.Section>
    </Modal>
  );
}
